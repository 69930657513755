.help-title{
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color:gray;
    width: 100%;
}

.help-content{
    margin-left:auto;
    margin-right:auto;
    max-width:75%;
    font-size: 1em;
    margin-bottom: 10px;
    color: gray;
}

.help-image-container{
    width: fit-content;
    margin: auto;
}

.help-image{
    max-width:1200px;
}