.workspace {
    max-width: 800px;
    min-width: 400px;
    background-color:rgb(255, 255, 255);
    border-radius: 10px;
    border-width: 1px;
    box-shadow: 2.5px 2.5px 5px gray;
    margin-top: 100px;
    height:80%;
    padding:10px;
    margin-left:auto;
    margin-right:auto;
}