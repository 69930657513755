.record-selector{
    width: 100%;
    font-size: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}


.record-selector-dropdown{
    height: 35px;
    color: deepskyblue;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width:250px;
}

.record-selector-option{
    font-size: 15px;
}

.record-selector-button-container{
    width:fit-content;
    margin-left: auto;
    width:100%;
    text-align:center;
}

.record-selector-dropdown-container{
    width: 100%;
    text-align:center;
}

.record-selector-section{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top:5px;
    padding-bottom:5px;
}

.record-selector-column{
    flex: 1;
}

.record-selector-column-right{
    flex: 1;
    text-align:left;
}