.two-column-layout{
    display: flex;
}

.left-column{
    flex: 1;
    margin-right: 1rem;
}

.right-column{
    flex: 1;
}