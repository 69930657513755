#logo {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #00b7ee;
    margin: 10px;
    padding:5px;
    width: 150px;
    text-align:center;
}

img {
    margin-right:auto;
    margin-left:auto;
    width: fit-content;
}

.logo-purple-text{
    color: #a300d4;
    font-size: 2vh;
    font-weight: bold;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.logo-gray-text {
    color: #747474;
    font-size: 2vh;
    font-weight: bold;
    text-align: center;
    width: 100%;
}