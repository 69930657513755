#footer{
    background-color: black;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 5vh;
    font-size: 1.5vh;
    padding-top:2vh;
    box-shadow: 0 0 0.1vh rgb(0, 0, 0);
}