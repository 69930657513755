.upload-button-open {
    margin:auto;
    margin-top:50px;
    border: 2px solid rgb(0, 119, 10);
    color: rgb(87, 87, 87);
    background-color: rgb(164, 255, 180);
    padding: 10px;
    cursor: pointer;
    font-size: 1.5vh;
    width:200px;
}

.upload-button-open:hover {
    font-weight: bold;
    background-color:rgb(238, 238, 238);
}

.upload-file-section, .select-file-section{
    background-color:rgb(238, 238, 238);
    border-radius:5px;
    margin:25px;
}

input[type="file"] {
    display: none;
}
  
.custom-file-upload {
    display: inline-block;
    border: 2px solid lightblue;
    color: rgb(87, 87, 87);
    background-color: white;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5vh;
    margin-top:10px;
    width:176px;
    margin:auto;
}

.custom-file-upload:hover {
    font-weight:bold;
    background-color:rgb(238, 238, 238);
}

.file-uploader{
    text-align: center;
}

.button-container{
    width:50%;
    margin:auto;
    display:flex;
}

.upload-button-closed{
    display:none;
}

.file-name{
    margin-top:10px;
    font-size: 1.5vh;
    color: gray;
}

.accepted-message{
    font-size: 2vh;
    color: rgb(0, 187, 0);
    width: fit-content;
    margin:auto;
    font-weight:normal;
    border-width: 1px;
    border-color:rgb(0, 187, 0);
    border-style: solid;
    padding:5px;
    border-radius:5px;
    margin-top:25px;
}

.failure-message{
    font-size: 2vh;
    color: rgb(187, 0, 0);
    width: fit-content;
    margin:auto;
    font-weight:normal;
    border-width: 1px;
    border-color:rgb(187, 0, 0);
    border-style: solid;
    padding:5px;
    border-radius:5px;
    margin-top:25px;
}

.waiting-for-upload-message{
    display:none;
}

.loading-animation-container{
    position: fixed;
    top: 45vh;
    left: 45%;
}