.toast-success--show {
    background-color: #1d8f39;
    color: white;
    border-radius: 5px;
    padding: 16px;
    position: absolute;
    top: 15%;
    left: 50%;
    z-index: 1000;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    width:500px;
    box-shadow: 0 0 0 1px #1d8f39;
}

.toast-success--hide {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none; /* Optional: Prevent interaction when hidden */
}

.toast-close-button{
    text-align: right;
    cursor: pointer;
    flex:1;
}

.toast-success__title{
    flex: 1;
    text-align:center;
    font-weight:bold;
}

.toast-upper-left{
    flex:1;
}

.title-bar{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
}