.collapsible-title-link{
    cursor: pointer;
}

.collapsible-component{
    margin: 5px;
    min-height: 3vh;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    border-radius: 5px;
}

.content-collapsed{
    display: none;
}

.content-expanded{
    display: block;
    min-height:10vh;
    padding: 10px;
}

.button-hide, .button-show{
}

.button-hide{
    display: none;
}

.button-show{
    border: 2px solid lightblue;
    color: rgb(87, 87, 87);
    background-color: white;
    padding: 5px;
    cursor: pointer;
    font-size: 1.5vh;
    width:25px;
    height: 25px;
    margin:auto;
    text-align: center;
}

.button-show:hover {
    font-weight: bold;
    background-color:lightgray;
}

.always-visible{
    padding: 10px;
    float:right;
    display: block;
    min-height: 2.5vh;
    font-size: 1.5vh;
    color: gray;
    text-align: left;
    background-color: rgb(255, 255, 255);
    flex: 9;
}

.headline{
    display: flex;
    padding-top:10px;
}

.dropdown{
    display: flex;
    justify-content: right;
}

.collapsible-title-link{
    font-size: 15px;
    color: gray;
}

.collapsible-title-link:hover{
    font-weight: bold;
}

.collapsible-title-link:active{
    color: black;
}