.navigation-menu-item {
    border-bottom-style: solid;
    border-bottom-width: 0.1vh;
    border-color: lightgray;
    background-color:white;
    color: rgb(72, 167, 255);
    font-size:2vh;
    height: 4vh;
    padding-top: 2vh;
    text-align: center;
    width: 100%;
    user-select: none; /* Prevent text selection */
}

.navigation-menu-item:hover {
    background-color: lightgray;
    transition: background-color 0.5s ease; /* Add transition property */
    color: black;
}

.navigation-menu-item:active {
    background-color: darkgray;
    color: white;
}

.selected {
    background-color: lightgray;
    color: black;
}