.tool{
    width:90%;
    padding: 10px;
    min-height:30vh;
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
    margin: auto;
}

.title{
    font-size: 20px;
    margin: 10px;
    color: rgb(72, 167, 255);
    font-weight: normal;
}
