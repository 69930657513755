.content-wrapper {
    display: flex;
}

.column {
    width:400px;
    margin-right: 25px;
}

.column-wide{
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    text-align: left;
}

.debug{
    margin-top: 100px;
    font-size:50px;
}

#loading-wrapper{
    justify-content:center;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    z-index:2000;
}

.grayed-out-background{
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
}

#loading-animation-wrapper{
    width:fit-content;
    position:fixed;
    top:50%;
    left:50%;
}

.hidden{
    display:none !important;
}

.toast-container{
    position: absolute;
    top: 50px;
    left:45%;
    z-index: 2000;
    padding: 10px;
    height:50px;
}

.pill{
    color: white;
    background-color:deepskyblue;
    padding: 5px;
    box-shadow: 0 0 0 1px lightgray;
    border-color: deepskyblue;
    border-radius: 5px;
    border-width: 1px;
    border-style:solid;
}

.pill-view-only{
    color: white;
    background-color:gray;
    padding: 5px;
    box-shadow: 0 0 0 1px lightgray;
    border-color: gray;
    border-radius: 5px;
    border-width: 1px;
    border-style:solid;
    pointer-events:none;
}


.pill-title-text{
    color: white;
    font-weight:bold;
    pointer-events:none;
}

.pill-content-section{
    display:flex;
    flex-direction: row;
}

.text__small{
    color:gray;
    font-size: 15px;
    pointer-events: none;
}

.text__medium{
    color:gray;
    font-size: 20px;
    pointer-events: none;
}

.text__large{
    color:gray;
    font-size: 25px;
    pointer-events: none;
}

button{
    height: 35px;
    border-style: none;
    background-color:#eeeeee;
    color: deepskyblue;
    width:fit-content;
    min-width: 35px;
    padding: 8px;
    cursor: pointer;
    border-radius:50px;
}

button:hover{
    background-color: darkgray;
}

button:active{
    background-color:rgb(255, 54, 255);
}