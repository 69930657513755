.dropdown-menu-close{
    display: none;
}

.dropdown-menu-open{
    display: block;
    position: absolute;
    background-color: white;
    border: 1px solid gray;
    z-index: 1;
    width: 100px;
    margin-top: 0.5rem;
    padding: 0.5rem;
}

.dropdown-menu-item{
    padding: 0.5rem;
    cursor: pointer;
}

.dropdown-menu{
    
}

.dropdown-menu-button{
    border: 2px solid lightblue;
    color: lightgray;
    background-color: white;
}