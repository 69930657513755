.record-detail{
}

.record-detail-table{
    padding: 10px;
}

.record-detail-field-name{
    border-radius: 0px;
    text-align: left;
    margin:0;
    font-weight: bold;
    padding: 5px;
    width:150px;
    color: #78b1eb;
}

.record-detail-field-data{
    padding: 5px;
    border-style: none;
    text-align: left;
    min-width: 200px;
    margin: 0;
}

th{
    border-style: none;
    padding: 5px;
    text-align: center;
}

.delete-record-from-detail{
    color: red;
    cursor: pointer;
    margin-right:auto;
    margin-left:10px;
}

.delete-record-from-detail:hover{
    color: white;
    background-color: red;
}

table, th, tr, td{
    border-collapse: collapse;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
}

.record-detail-remove-button{
    color: white;
    cursor: pointer;
    float: right;
}

.record-detail-remove-button:hover{
    color:gray;
}

.record-detail-content-section{
    flex: 3;
    text-align:left;
}

.record-detail-button-section{
    flex: 1;
    text-align:right;
}