.workforce-rule-content{
    display:flex;
}

.workforce-rule-content-item{
    cursor: pointer;
    flex: 1;
    border-style: solid;
    border-width: 1px;
    border-color:lightgray;
    padding: 10px;
    margin: 10px;
    border-radius:5px;
}

.workforce-rule-content-item:hover{
    border-color: green;
    background-color:lightgray;
}

.workforce-rule-content-item-label{
    font-weight: bold;
}

.workforce-rule-content-item-value{
    font-size: 10px;
    color:gray;
}

.run-now-button{
    margin:10px;
    border-style: solid;
    border-width: 1px;
    border-color:green;
    padding: 5px;
    border-radius:5px;
    background-color: lightgreen;
    width: 100px;
}

.run-now-button:hover{
    cursor: pointer;
    background-color: green;
    color:white;
}

.button-container{
    width: fit-content;
    margin-left:auto;
    margin-right:auto;
}

.sub-item{
    border-width: 1px;
    border-style: solid;
    border-radius: 2.5px;
    padding: 2.5px;
}