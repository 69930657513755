#skill-groups-page{

}

.skill-group-help-title{
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color:gray;
}

.skill-group-help-content{
    margin-left:auto;
    margin-right:auto;
    max-width:75%;
    font-size: 1em;
    margin-bottom: 10px;
    color: gray;
}

.skill-group-help-image-container{
    width: fit-content;
    margin: auto;
}

.action-icons{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.action-icon{
    flex: 1;
    width:35px;
}


.scrollable-container {
    height: 500px; /* Set the height of the scrollable area */
    overflow-y: scroll; /* Enable vertical scrolling */
    padding: 10px; /* Optional: Add padding for inner spacing */
}

/* Webkit-based browsers (Chrome, Safari) */
.scrollable-container::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
}

.scrollable-container::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

/* Firefox */
.scrollable-container {
    scrollbar-width: thin; /* Make the scrollbar thinner */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

.show-refresh-button{
    display: inline-block;
    width: 35px;
    padding: 0px;
}

.hide-refresh-button{
    display: none;
}