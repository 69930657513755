.create-record-button{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    background-color: #4CAF50;
    color: white;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.field-label{
    font-size: 20px;
    margin:5px;
}

.field-input{
    height: 25px;
    border-radius:5px;
    background-color:#e7e7e7;
    border-style: none;
    margin: 5px;
    width: 350px;
}

.field-name{
    width: 100%;
    height: 50px;
    font-size: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 10px;
}

.field-container{
    width: 550px;
    margin:5px;
}

.create-record-modal-content{
    background-color:rgb(255, 255, 255);
    width:600px;
}

.create-record-title{
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
    color: gray;
    pointer-events:none;
}

.create-record-field-label{
    color: gray;
    font-weight: normal;
}

.create-record-submit-button-container{
    width:fit-content;
    margin-left:auto;
    margin-right:auto;
    height:100px;
}


.create-record-field-content {
    overflow-y: auto; /* Enable vertical scrolling */
    height: 500px; /* Adjust this value as needed */
    scrollbar-width: none; /* For Firefox */
}

.create-record-field-content::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.create-record-field-content:hover {
    scrollbar-width: thin; /* For Firefox */
}

.create-record-field-content:hover::-webkit-scrollbar {
    display: block; /* For Chrome, Safari, and Opera */
}

.create-record-title-container{
    text-align: center;
}

.required-indicator{
    color: red;
}