#header{
    position: fixed;
    left:0;
    top:0;
    background-color:white;
    min-height:75px;
    width: 100%;
    color: rgb(0, 0, 0);
    text-align: left;
    box-shadow: 0px 0px 0px 5px rgba(180, 180, 180, 0.2);
    display: flex;
}

.logo-container{
    flex: 1;
}

.client-selector{
    flex: 1;
}

.logout-button-container{
    float:right;
    margin-right: 25px;
}

