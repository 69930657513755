.window-open {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    height:600px;
}

.window-close{
    display: none;
}

.modal-window-content{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.modification-icon-slot{
    width:100%;
    min-height:25px;
    text-align:right;
}

.modal-window-delete-button-container{
    margin-left:5px;
    margin-right:5px;
}

.button-close{
    float:right;
}

.open-button{
    width:35px;
}