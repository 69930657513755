.open{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.close{
    display: none;
}

.modal-content{
    padding: 20px;
    width: fit-content;
    margin:auto;
    margin-top:35vh;
}