.page{
    padding: 10px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.page-title{
    font-size: 15px;
    color: gray;
    text-align: center;
    margin-bottom:25px;
}

.help-button-container {
    width: fit-content;
    margin-left: auto;
}