.workforce-rule{
    background-color:white;
}

.workforce-rule-title{
    font-size: 20px;
    padding: 10px;
    text-align:center;
}

.scrollable-container {
    height: 500px; /* Set the height of the scrollable area */
    overflow-y: scroll; /* Enable vertical scrolling */
    padding: 10px; /* Optional: Add padding for inner spacing */
}

/* Webkit-based browsers (Chrome, Safari) */
.scrollable-container::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
}

.scrollable-container::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

/* Firefox */
.scrollable-container {
    scrollbar-width: thin; /* Make the scrollbar thinner */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}