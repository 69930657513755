.tile-icon{
    width: 100px;
    margin: auto;
}

.icon-container{
    width:fit-content;
    margin-left:auto;
    margin-right:auto;
    justify-content: center;
}

.lifecycle-infographic{
    width: 400px;
}

.infographic-container{
    color: gray;
    max-width: 410px;
    margin:auto;
    padding: 10px;
}

.wfaccelerator{
    width: 600px;
}