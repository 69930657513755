.insight-data-point{
    flex: 1;
}

.insight{
    display: flex;
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
    border-radius: 5px;
    padding: 10px;
    color: gray;
    margin-bottom: 10px;
    font-size: 1.5vh;
}