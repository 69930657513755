.loginButton{
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius:5px;
}

.grayedOutBackground{
    background-color:white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.window{
    width: 200px;
    height: 200px;
}

.title-text{
    color:gray;
}
.login-box-header{
    margin-bottom:25px;
}

.login-footer{
    position:absolute;
    bottom:0;
    width:100%;
    background-color:#851557;
    color:white;
    text-align: center;
    height:50px;
    padding: 10px;
}

.call-center-worker{
    position:absolute;
    top:20%;
    left:100px;
    width:500px;
    z-index:-10;
}

.tech-lines{
    width:100%;
}

.graphics-container{
    height: 60vh;
    width: 100%;
    z-index: -20;
    background-color:rgb(247, 247, 247);
    position:absolute;
    left:0;
    top:15%;
}